import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../service/Api";

export const getUserList = createAsyncThunk("getUserList", async (data) => {
  const response = await API.post(`listUsers`, data);
  return response.data;
});


export const deleteUserAccount = createAsyncThunk("deleteUserAccount", async (data) => {
  const response = await API.delete(`deleteUser/${data?.id}`);
  return response.data;
});

export const deleteAdminAccount = createAsyncThunk("deleteAdminAccount", async (data) => {
  const response = await API.delete(`deleteAdmin/${data?.id}`);
  return response.data;
});

export const resetUserAccount = createAsyncThunk("resetUserAccount", async (data) => {
  const response = await API.post(`Forgot_Password/${data?.id}`);
  return response.data;
});


export const updateUserByAdmin = createAsyncThunk("updateUserByAdmin", async (data) => {
  const response = await API.put(`updateUserByAdmin`,data);
  return response.data;
});





export const suspendUserAccount = createAsyncThunk("suspendUserAccount", async (data) => {
  const response = await API.post(`suspendUser`,data);
  return response.data;
});




export const forgetPassword = createAsyncThunk("forgetPassword", async (data) => {
    const response = await API.post('forgotPassword', data);
    return response.data;
  });


  export const verifyOtp = createAsyncThunk("verifyOtp", async (data) => {
    const response = await API.post(
      `verifyOtp/${ data.adminId}`, 
      { otp: data.otp },
    );
    return response.data;
  });

  export const resetPassword = createAsyncThunk("resetPassword", async (data) => {
    const response = await API.post(
      `changePassword/${data.adminId}`, 
      { newPassword: data.newPassword },
    );
    return response.data;
  });

  export const userDetailById = createAsyncThunk("userDetailById", async (data) => {
    const response = await API.get(`getUsers`, {
      params: {
        id: data.id,
        type: data.type,
      },
    });
    return response.data;
  });

  export const createAdmin = createAsyncThunk("createAdmin", async (data) => {
    const response = await API.post('createNewUser', data);
    return response.data;
  });

  export const editSubAdmin = createAsyncThunk("editSubAdmin", async (data) => {
    const response = await API.put(`updateAdminById`, data);
    return response.data;
  });



  export const listSubAdminOrUser = createAsyncThunk("listSubAdminOrUser", async (data) => {
    const response = await API.get(
      `listAdmins`);
    return response.data;
  });



  export const subAdminDetails = createAsyncThunk("subAdminDetails", async (data) => {
    const response = await API.get(`getAdminById/${data?.id}`);
    return response.data;
  });



