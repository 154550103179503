import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../service/Api";

export const getListing = createAsyncThunk("getListing", async (data) => {
  const response = await API.get('get_listing');
  return response.data;
});


export const getCategory = createAsyncThunk("getCategory", async (data) => {
    const response = await API.get('get_Categories');
    return response.data;
  });

  export const getSubCategory = createAsyncThunk("getSubCategory", async (categoryId) => {
    const response = await API.get(`get_Subcategories/${categoryId}`);
    return response.data;
  });


  export const getItemDetailById = createAsyncThunk("getItemDetailById", async (itemId) => {
    const response = await API.get(`getItemById/${itemId}`);
    return response.data;
  });
