import { configureStore } from "@reduxjs/toolkit";
import  globalReducer from "../Redux/Reducers/globalSlice" 
import userReducer from "../Redux/Reducers/authSlice"
import itemReducer from "../Redux/Reducers/userList"

export const store = configureStore({
  reducer: {
    globalSlice: globalReducer,
    userAuth: userReducer,
    itemAuth : itemReducer
  },
});
